import { Box, Button, Dialog, DialogContent, Paper, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import SocialLogin from '../sharedComponents/src/SocialLogin/SocialLogin'
import { IAppConfig, getAppConfig } from '../sharedComponents/src/globalHeader/services/AppConfigService'
import { analyticsManager } from '../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import { colors } from '../sharedComponents/src/globalHeader/theme/colors'
import { useStoreActions } from '../store/hooks'

const loginMenuStyles = {
    '& .MuiDialog-paperScrollPaper': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        '@media (max-width: 600px)': {
            display: 'grid',
        },
    },
}
const dialogStyles = {
    position: 'relative',
    height: 'auto',
    overflow: 'hidden',
    marginRight: '0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
}

const boxStyles = {
    margin: '0',
    justifySelf: 'center',
    alignSelf: 'center',
    height: 'auto',
    flexGrow: 1,
    display: 'flex',
    width: '100%',
    border: `0.313rem solid ${colors.white}`,
    borderRadius: '0rem',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '32px',
    gap: '32px',
}

const titleStyles = {
    fontSize: '64px',
    lineHeight: '100%',
    textAlign: 'center',
}

export interface LoginMenuProps {
    dialog: boolean
}

export const LoginMenu = (props: LoginMenuProps) => {
    const { dialog } = props
    const setAuthOverlayTrigger = useStoreActions((state) => state.setAuthOverlayTrigger)
    const [appConfig, setAppConfig] = useState<IAppConfig | null>(null)

    useEffect(() => {
        const config = getAppConfig()
        setAppConfig(config)
    }, [])

    const LoginMenu = (
        <Paper sx={{ ...boxStyles }}>
            <Typography variant="h1" sx={titleStyles}>
                let's get started
            </Typography>
            <Stack direction="column" alignItems="center" spacing={2}>
                <Button
                    variant="contained"
                    onClick={() => {
                        setAuthOverlayTrigger('signin')
                        analyticsManager.dispatchEvent('openLogin', {
                            origin: 'welcomeScreen',
                        })
                    }}
                    data-gaid="open-signin"
                    data-gaorigin="welcome_screen"
                >
                    Log in
                </Button>
                <Button
                    onClick={() => {
                        setAuthOverlayTrigger('signup')
                        analyticsManager.dispatchEvent('openSignup', {
                            origin: 'welcomeScreen',
                        })
                    }}
                    variant="outlined"
                    data-gaid="open-signup"
                    data-gaorigin="welcome_screen"
                >
                    Sign up
                </Button>

                <Button
                    variant="outlined"
                    onClick={() => {
                        setAuthOverlayTrigger('guestSignUp')
                    }}
                    data-gaid="play-as-guest-button"
                >
                    play as a guest
                </Button>
            </Stack>
            {/* SOCIAL LOGIN BUTTONS FLASHING... */}
            <Box height={'124px'}>
                {!(appConfig && appConfig.hideSSO) && (
                    <SocialLogin
                        title="Or continue with:"
                        onSignUpSocial={(res) => setAuthOverlayTrigger({ name: 'socialSignUp', data: res })}
                        onLoginSuccessful={() => setAuthOverlayTrigger('successLogin')}
                        logoutHandler={async () => setAuthOverlayTrigger('logout')}
                        apiURL={import.meta.env.VITE_REACT_APP_AMS_URL}
                        googleClientId={import.meta.env.VITE_REACT_APP_GOOGLE_CLIENT_ID}
                        facebookAppId={import.meta.env.VITE_REACT_APP_FACEBOOK_APP_ID}
                        facebookApiVersion={import.meta.env.VITE_REACT_APP_FACEBOOK_API_VERSION}
                        appleClientId={import.meta.env.VITE_REACT_APP_APPLE_CLIENT_ID}
                        appleRedirectURL={import.meta.env.VITE_REACT_APP_APPLE_REDIRECT_URL}
                        theme="dark"
                        titleFontStyles={{
                            fontSize: '1.3rem',
                        }}
                    />
                )}
            </Box>
        </Paper>
    )

    return (
        <>
            {dialog ? (
                <Dialog
                    open={true}
                    className={'loginMenu'}
                    BackdropProps={{
                        onClick: undefined,
                    }}
                    disableEnforceFocus
                    sx={loginMenuStyles}
                >
                    <DialogContent sx={dialogStyles}>{LoginMenu}</DialogContent>
                </Dialog>
            ) : (
                LoginMenu
            )}
        </>
    )
}
