import * as Sentry from '@sentry/react'
import { StoreProvider } from 'easy-peasy'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import App from './App'
import { ObserveView } from './features/ObserveView/ObserveView'
import PuzzlesTrainingViewV2 from './features/PuzzlesView/PuzzlesTrainingViewV2'
import { AnalysisView } from './features/analysisView/AnalysisView'
import { GameView } from './features/gameView/GameView'
import reportWebVitals from './reportWebVitals'
import store from './store/store'

!window.location.href.includes('localhost.iccstage') &&
    Sentry.init({
        dsn: 'https://643de0fa76db4615a1fec0ad6daf5af9@o4504786832326656.ingest.sentry.io/4504808632221696',
        integrations: [new Sentry.BrowserTracing()],
        environment: import.meta.env.VITE_REACT_APP_ENV,

        // for production, this value should be lower (e.g. 0.25) to lower costs
        // 1 means that 100% of transactions will be sent to Sentry for processing while 0.2 means there is a 20% chance that a transaction will be sent to Sentry
        tracesSampleRate: 1.0,

        // tracesSampler is an alternative to tracesSampleRate that allows you to sample individual transactions based on their context. This is useful if you want to sample only a subset of your traffic, or if you want to sample transactions with different rates. For example:
        // tracesSampler: (samplingContext) => {
        //     if (samplingContext?.transactionContext?.name?.startsWith('ams/')) {
        //         return 0.5
        //     }
        //     return 1.0
        // },
        release: 'icc@' + import.meta.env.VITE_REACT_APP_VERSION,
    })

const router = createBrowserRouter(
    [
        {
            id: 'root',
            path: '/',
            element: <App />,
            children: [
                {
                    id: 'index',
                    path: '/',
                    element: <GameView />,
                },
                {
                    id: 'analysis',
                    path: '/analysis',
                    element: <AnalysisView />,
                },
                {
                    id: 'watch',
                    path: '/watch',
                    element: <ObserveView />,
                },
                // {
                //     id: 'puzzles',
                //     path: '/puzzles',
                //     element: <PuzzlesQuestView />,
                // },
                {
                    id: 'training',
                    path: '/training',
                    element: <PuzzlesTrainingViewV2 />,
                },
                {
                    id: 'default',
                    path: '*',
                    element: <Navigate to="/" />,
                },
            ],
        },
    ],
    {
        basename: '/',
    },
)

store
    .getActions()
    .initUser()
    .then(() => {
        const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
        root.render(
            <React.StrictMode>
                <Sentry.ErrorBoundary fallback={<p>Checkmate</p>}>
                    <StoreProvider store={store}>
                        <RouterProvider router={router} />
                    </StoreProvider>
                </Sentry.ErrorBoundary>
            </React.StrictMode>,
        )
    })

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
