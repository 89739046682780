import SearchIcon from '@mui/icons-material/Search'
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    IconButton,
    InputBase,
    Palette,
    Popover,
    Stack,
    Typography,
    useTheme,
} from '@mui/material'

import { useEffect, useState } from 'react'
import BlitzIcon from '../../assets/icons/blitz.svg?react'
import BulletIcon from '../../assets/icons/bullet.svg?react'
import FilterIcon from '../../assets/icons/filter.svg?react'
import RapidIcon from '../../assets/icons/rapid.svg?react'
import WorldIcon from '../../assets/icons/world.svg?react'
import { useStoreState } from '../../store/hooks'

const wrapperStyles = (palette: Palette) => ({ borderBottom: `0.125rem solid ${palette.text.primary}` })

const inputStyles = {
    borderRadius: '0',
    fontSize: '1.25rem',
    flex: 1,
}

const sortingOptions = [
    {
        value: 'bullet',
        label: (
            <Stack direction="row" alignItems="center" style={{ lineHeight: '1' }}>
                <BulletIcon style={{ marginRight: '0.25rem', fill: '#FFC58C', height: '1rem', width: 'auto' }} />
                Bullet
            </Stack>
        ),
    },
    {
        value: 'blitz',
        label: (
            <Stack direction="row" alignItems="center" style={{ lineHeight: '1' }}>
                <BlitzIcon style={{ marginRight: '0.25rem', fill: '#FFC58C', height: '1rem', width: 'auto' }} />
                Blitz
            </Stack>
        ),
    },
    {
        value: 'rapid',
        label: (
            <Stack direction="row" alignItems="center" style={{ lineHeight: '1' }}>
                <RapidIcon style={{ marginRight: '0.25rem', fill: '#FFC58C', height: '1rem', width: 'auto' }} />
                Rapid
            </Stack>
        ),
    },
    {
        value: 'classic',
        label: (
            <Stack direction="row" alignItems="center" style={{ lineHeight: '1' }}>
                <WorldIcon style={{ marginRight: '0.25rem', fill: '#FFC58C', height: '1rem', width: 'auto' }} />
                Classic
            </Stack>
        ),
    },
]

const filterOptions = [
    { value: 'recentlyPlayed', label: 'Recent Opponents' },
    { value: 'nearMyRating', label: 'Near My Rating' },
]

export type userSearchBarProps = {
    searchTerm: string
    onSearchChange: (value: string) => void
    filter: string
    onFilterChange: (value: string) => void
}

type FilterOptionProps = {
    option: any
    selected: boolean
    onClick: () => void
}

const FilterOption = ({ option, selected, onClick }: FilterOptionProps) => {
    const settings = useStoreState((state) => state.gameView.settings)

    return (
        <FormControlLabel
            control={<Checkbox />}
            label={option.label}
            onChange={onClick}
            checked={selected}
            sx={{ marginBottom: '-0.875rem', textTransform: 'uppercase' }}
        />
    )
}

export function UserSearchBar({ searchTerm, onSearchChange, filter, onFilterChange }: userSearchBarProps) {
    const { palette } = useTheme()

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [localFilter, setLocalFilter] = useState(filter)

    useEffect(() => {
        setLocalFilter(filter)
    }, [filter])

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleDoneClick = () => {
        onFilterChange(localFilter)
        handleClose() // close the popover
    }

    const open = Boolean(anchorEl)
    const id = open ? 'filters-popover' : undefined

    return (
        <Stack direction="row" flexDirection={'row'} style={wrapperStyles(palette)}>
            <IconButton type="submit" aria-label="search">
                <SearchIcon />
            </IconButton>
            <InputBase
                value={searchTerm}
                onChange={(e) => onSearchChange(e.target.value)}
                placeholder="Search for a user"
                style={inputStyles}
            />
            <Stack
                direction="row"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                aria-describedby={id}
                onClick={handleClick}
            >
                <FilterIcon />
                <Typography>Filter</Typography>
            </Stack>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Stack
                    direction="column"
                    gap="1.5rem"
                    style={{ border: `1px solid ${palette.text.primary}`, padding: '0.75rem' }}
                >
                    <Box>
                        <Typography
                            style={{
                                fontFamily: 'Space Grotesk',
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                fontSize: '1.125rem',
                            }}
                        >
                            Time Control
                        </Typography>
                        <Stack direction="column" style={{ marginLeft: '0.5rem' }}>
                            {sortingOptions.map((option) => (
                                <FilterOption
                                    option={option}
                                    selected={localFilter === option.value}
                                    onClick={() => setLocalFilter(option.value)}
                                    key={option.value}
                                />
                            ))}
                        </Stack>
                    </Box>
                    <Box>
                        <Typography
                            style={{
                                fontFamily: 'Space Grotesk',
                                fontWeight: 700,
                                textTransform: 'uppercase',
                                fontSize: '1.125rem',
                            }}
                        >
                            Sorting Type
                        </Typography>
                        <Stack direction="column" style={{ marginLeft: '0.5rem' }}>
                            {filterOptions.map((option) => (
                                <FilterOption
                                    option={option}
                                    selected={localFilter === option.value}
                                    onClick={() => setLocalFilter(option.value)}
                                    key={option.value}
                                />
                            ))}
                        </Stack>
                    </Box>
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="outlined" onClick={handleDoneClick}>
                            Done
                        </Button>
                    </Box>
                </Stack>
            </Popover>
        </Stack>
    )
}
