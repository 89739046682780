import { Box, Button, Stack } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import useSound from 'use-sound'
import { getLastPosition, getPositionById } from '../../chess/gameTree'
import { Color, Move } from '../../chess/types'
import { PageView, PlayerInfoBar } from '../../components/PlayerInfoBar'
import { TimeControl, determineTimeControlName } from '../../functions/determineTimeControl'
import { getPlayerInfos } from '../../functions/getPlayerInfos'
import { Layout, useResponsiveSizings } from '../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions, useStoreState } from '../../store/hooks'
import { ChessBoard } from '../chessboard/ChessBoard'
import { Notation } from '../notation/Notation'
import { NotationControls } from '../notation/components/NotationControls'
import ConfirmationDialogs, { ConfirmationType } from './components/confirmationDialogs/ConfirmationDialogs'
import { GameEndControls } from './components/gameEndControls/GameEndControls'
import { GameOverDialog } from './components/gameOverDialog/GameOverDialog'
import GameServerConnector from './components/gameServerConnector/GameServerConnector'

import { GroupNames, LoginState } from '../../sharedComponents/src/globalHeader/GlobalHeader'
import { GameViewState, StateChangeRequest } from './GameViewModel'

import { format } from 'date-fns'
import { useLocation, useNavigate } from 'react-router-dom'
import AppLayout from '../../components/AppLayout/AppLayout'
import { OpponentDisconnectedSnackbar } from '../../components/AppLayout/StyledSnackbar/OpponentDisconnectedSnackbar'
import StyledSnackbar from '../../components/AppLayout/StyledSnackbar/StyledSnackbar'
import Scoreboard from '../../components/Scoreboard/Scoreboard'
import { ConnectionFailedOverlay } from '../../components/overlays/ConnectionFailedOverlay/ConnectionFailedOverlay'
import getPlayersStats, { IOverallRecord } from '../../functions/getPlayersStats'
import convertMinutesToTime from '../../hooks/convertMinutesToTime'
import { useConfig } from '../../sharedComponents/src/globalHeader/services/AppConfigService'
import { analyticsManager } from '../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import { MM_MESSAGES } from '../matchMakingDrawer/matchMakingConnector/MMTypes'
import { BoardOverlay } from './components/BoardOverlay'
import GameViewClock from './components/clock/GameViewClock'
import ConfirmationVictoryDialog from './components/confirmationVictoryDialog/confirmationVictoryDialog'

export const GameView = () => {
    // ----------------------------------------------------------------- state
    const drawerOpen = useStoreState((state) => state.drawerOpen)
    const gameData = useStoreState((state) => state.gameView.gameData)
    const gameState = useStoreState((state) => state.gameView.gameState)
    const myColor = useStoreState((state) => state.gameView.myColor)
    const flipped = useStoreState((state) => state.gameView.flipped)
    const gameResult = useStoreState((state) => state.gameView.gameResult)
    const gameTree = useStoreState((state) => state.gameView.deferredGameTree)
    const currentPositionId = useStoreState((state) => state.gameView.deferredCurrentPositionId)
    const drawOfferStatus = useStoreState((state) => state.gameView.drawOfferStatus)
    const abortOfferStatus = useStoreState((state) => state.gameView.abortOfferStatus)
    const gameEndOpen = useStoreState((state) => state.gameView.gameEndEnabled)
    const userData = useStoreState((state) => state.userData)
    const rematchMatchData = useStoreState((state) => state.matchMaker.rematchMatchData)
    const settings = useStoreState((state) => state.gameView.settings)
    const snackbarMessage = useStoreState((state) => state.snackbarMessage)
    const userGroups = useStoreState((state) => state.userGroups)
    const opening = useStoreState((state) => state.gameView.opening)
    const token = useStoreState((state) => state.token)
    const sessionResultHistory = useStoreState((state) => state.gameView.sessionResultHistory)
    const connectionStatus = useStoreState((state) => state.gameView.connectionStatus)
    const matchData = useStoreState((state) => state.gameView.matchData)
    const bots = useStoreState((state) => state.matchMaker.bots)

    // ----------------------------------------------------------------- actions
    const setActiveAnalysisTab = useStoreActions((state) => state.setActiveAnalysisTab)
    const setDrawerOpen = useStoreActions((state) => state.setDrawerOpen)
    const sendMove = useStoreActions((state) => state.gameView.sendMove)
    const sendResign = useStoreActions((state) => state.gameView.sendResign)
    const sendOfferDraw = useStoreActions((state) => state.gameView.sendOfferDraw)
    const sendAbortOfferRequest = useStoreActions((state) => state.gameView.sendAbortOfferRequest)
    const setFlipped = useStoreActions((state) => state.gameView.setFlipBoard)
    const setDrawStatus = useStoreActions((state) => state.gameView.setDrawStatus)
    const setAbortStatus = useStoreActions((state) => state.gameView.setAbortStatus)
    const sendAcceptOfferDraw = useStoreActions((state) => state.gameView.sendAcceptOfferDraw)
    const sendAcceptAbortOffer = useStoreActions((state) => state.gameView.sendAcceptAbortOffer)
    const sendRejectAbortOffer = useStoreActions((state) => state.gameView.sendRejectAbortOffer)
    const sendRejectOfferDraw = useStoreActions((state) => state.gameView.sendRejectOfferDraw)
    const clearGSConnection = useStoreActions((state) => state.gameView.shutDownGSConnection)
    const resetGame = useStoreActions((state) => state.gameView.resetGame)
    const setGameEndEnabled = useStoreActions((state) => state.gameView.setGameEndEnabled)
    const setActiveTab = useStoreActions((state) => state.setActiveTab)
    const sendRematchChallenge = useStoreActions((state) => state.matchMaker.sendRematchChallenge)
    const setRematchStatus = useStoreActions((state) => state.matchMaker.setRematchStatus)
    const setAnalysisGameTree = useStoreActions((state) => state.analysisMode.setGameTree)
    const setAnalysisCurrentPositionId = useStoreActions((state) => state.analysisMode.setCurrentPositionId)
    const setSnackbarMessage = useStoreActions((state) => state.setSnackbarMessage)
    const setPGNHeader = useStoreActions((state) => state.setPGNHeader)
    const setToPreviousPosition = useStoreActions((state) => state.gameView.setToPreviousPosition)
    const setToNextPosition = useStoreActions((state) => state.gameView.setToNextPosition)
    const setToFirstPosition = useStoreActions((state) => state.gameView.setToFirstPosition)
    const setToLastPosition = useStoreActions((state) => state.gameView.setToLastPosition)
    const setResultType = useStoreActions((state) => state.analysisMode.setResultType)

    const gameStartSoundURL = `/assets/sounds/${settings.soundStyle}/GameStart.mp3`
    const [playGameStart] = useSound(gameStartSoundURL, { volume: 0.25 })

    // ----------------------------------------------------------------- hooks
    const { layout, playerInfoMode } = useResponsiveSizings()
    const [dialog, setDialog] = useState<ConfirmationType | undefined>()
    const [sentOfferDraw, setSentOfferDraw] = useState(false)
    const [sentOfferAbort, setSentOfferAbort] = useState(false)
    const [snackBarOpen, setSnackBarOpen] = useState(false)
    const [tabBar, setTabBar] = useState(0)
    const [overallRecord, setOverallRecord] = useState<IOverallRecord | undefined>(undefined)
    const [showBotLevel, setShowBotLevel] = useState(false)

    const location = useLocation()

    useEffect(() => {
        let searchParams = ''
        if (location.search == '' && location.hash !== '') {
            searchParams = location.hash.replace('#/', '')
        } else {
            searchParams = location.search
        }
        const params = new URLSearchParams(searchParams)
        const paramsData = params.getAll('system')
        const param = paramsData[0]
        useConfig(param, import.meta.env.VITE_REACT_APP_AMS_URL)
    }, [])

    const handleSnackBarOpenClose = () => {
        setSnackBarOpen(false)
        setRematchStatus(undefined)
        setSnackbarMessage(undefined)
    }

    useEffect(() => {
        if (userData.state !== LoginState.LOGGED_IN) {
            setDrawerOpen('closed')
        } else {
            setDrawerOpen('open')
        }
    }, [userData.state])

    useEffect(() => {
        if (gameState === GameViewState.GAME_RUNNING) {
            setDrawerOpen('closed')
            setResultType(undefined)
            const showLevel = bots.some(
                (bot) =>
                    bot.difficultyLevel === -1 &&
                    (bot.id === gameData?.player1.playerId || bot.id === gameData?.player2.playerId),
            )
            setShowBotLevel(showLevel)
        }
    }, [gameState])

    // play a start sound when the game starts
    useEffect(() => {
        if (gameState === GameViewState.GAME_RUNNING && matchData?.messageType !== MM_MESSAGES.PLAYER_IS_PLAYING) {
            if (settings.soundOn) {
                playGameStart()
            }
        }
    }, [gameState])

    useEffect(() => {
        if (snackbarMessage !== undefined) {
            setSnackBarOpen(true)
        }
    }, [snackbarMessage])

    // prevent leaving the page if the game is running.
    useBeforeunload((event) => {
        console.log('beforeunload event fired. gameState:', gameState)
        if (gameState === GameViewState.GAME_RUNNING) {
            event.preventDefault()
        }
    })

    // if a draw offer has come in, open the dialog
    useEffect(() => {
        if (drawOfferStatus === StateChangeRequest.RECEIVED) setDialog(ConfirmationType.ACCEPT_DRAW)
    }, [drawOfferStatus])

    // if an abort offer has come in, open the dialog
    useEffect(() => {
        if (abortOfferStatus === StateChangeRequest.RECEIVED) setDialog(ConfirmationType.ACCEPT_ABORT)
    }, [abortOfferStatus])

    // if draw was cancelled, close the dialog and show a snackBarOpen
    useEffect(() => {
        if (drawOfferStatus === StateChangeRequest.REMOVED) {
            setDialog(undefined)
            setSnackbarMessage(
                `${myColor === Color.White ? playerBlack?.name : playerWhite?.name} declined your draw offer`,
            )
            setSnackBarOpen(true)
        }
    }, [drawOfferStatus])

    // if abort was cancelled, close the dialog and show a snackBarOpen
    useEffect(() => {
        if (abortOfferStatus === StateChangeRequest.REMOVED) {
            setDialog(undefined)
            setSnackbarMessage(
                `${myColor === Color.White ? playerBlack?.name : playerWhite?.name} declined your abort offer`,
            )
            setSnackBarOpen(true)
        }
    }, [abortOfferStatus])

    const gtpos = useMemo(() => getPositionById(gameTree, currentPositionId), [gameTree, currentPositionId, gameState])

    const onMove = useCallback(
        (move: Move) => {
            if (gameState !== GameViewState.GAME_RUNNING) return false

            sendMove(move)
            return true
        },
        [gameState, sendMove],
    )

    //  confirm dialog confirm handling
    const handleConfirm = (type: ConfirmationType) => {
        if (type === ConfirmationType.RESIGN) {
            sendResign()
            analyticsManager.dispatchEvent('resign', {})
        }
        if (type === ConfirmationType.OFFER_DRAW) {
            sendOfferDraw()
            setSentOfferDraw(true)
            analyticsManager.dispatchEvent('offerDraw', {})
        }
        if (type === ConfirmationType.ASK_FOR_ABORT) {
            sendAbortOfferRequest()
            analyticsManager.dispatchEvent('offerAbort', {})
            setSentOfferAbort(true)
        }
        if (type === ConfirmationType.ACCEPT_DRAW) {
            setDrawStatus(undefined)
            sendAcceptOfferDraw()
        }
        if (type === ConfirmationType.ACCEPT_ABORT) {
            setAbortStatus(undefined)
            sendAcceptAbortOffer()
        }
        setDialog(undefined)
    }

    //  confirm dialog cancel handling
    const handleCancel = (type: ConfirmationType) => {
        if (type === ConfirmationType.ACCEPT_DRAW) {
            setDrawStatus(undefined)
            sendRejectOfferDraw()
        }
        if (type === ConfirmationType.ACCEPT_ABORT) {
            setAbortStatus(undefined)
            sendRejectAbortOffer()
        }
        setDialog(undefined)
    }

    const hasNextMoves = gtpos.nextMoveIds.length > 0
    const hasPrevMoves = gtpos.previousMoveId !== undefined

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'ArrowLeft') {
                event.preventDefault()
                setToPreviousPosition()
            }
            if (event.key === 'ArrowRight') {
                event.preventDefault()
                setToNextPosition()
            }
            if (event.key === 'ArrowUp') {
                event.preventDefault()
                setToFirstPosition()
            }
            if (event.key === 'ArrowDown') {
                event.preventDefault()
                setToLastPosition()
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [setToFirstPosition, setToLastPosition, setToPreviousPosition, setToNextPosition])

    useEffect(() => {
        if (
            gameData &&
            gameData?.matchKind === 'human_vs_human' &&
            (userData?.userData?.over_12_years_old || userGroups.some((group) => group === GroupNames.GUEST))
        ) {
            getOverallRecord()
        }
    }, [gameData, userData, gameState])

    const whiteClock = <GameViewClock color={Color.White} extraClass={playerInfoMode} />

    const blackClock = <GameViewClock color={Color.Black} extraClass={playerInfoMode} />

    const { playerWhite, playerBlack } = getPlayerInfos(gameData)
    const playerTop = flipped ? playerWhite : playerBlack
    const playerBottom = flipped ? playerBlack : playerWhite
    const isRated = !!gameData && gameData.rated

    let matchTitle = ''
    if (!!gameData) {
        let timeControlName = determineTimeControlName(
            gameData.timeMode.durationMinutes,
            gameData.timeMode.clockIncrementSeconds,
        )
        //capitalize first letter
        timeControlName = (timeControlName.charAt(0).toUpperCase() + timeControlName.slice(1)) as TimeControl
        matchTitle =
            gameData.timeMode.durationMinutes + ' + ' + gameData.timeMode.clockIncrementSeconds + ' ' + timeControlName
    }

    const getOverallRecord = useCallback(async () => {
        if (!token) return

        const currentUserId = myColor === Color.White ? playerWhite?.playerId : playerBlack?.playerId
        const opponentUserId = myColor === Color.White ? playerBlack?.playerId : playerWhite?.playerId

        const result = await getPlayersStats(currentUserId || '', opponentUserId || '', token)
        if (result) {
            setOverallRecord(result)
        }
    }, [token, playerWhite?.playerId, playerBlack?.playerId])

    const navigate = useNavigate()

    const onAnalysisClick = () => {
        if (gameData && gameResult) {
            const whitePlayer = gameData.player1.playerColor === 'white' ? gameData.player1 : gameData.player2
            const blackPlayer = gameData.player1.playerColor === 'black' ? gameData.player1 : gameData.player2
            const whitePlayerOldRating = whitePlayer.ratings
                ? whitePlayer.ratings[gameData.timeMode.name].rating
                : undefined
            const blackPlayerOldRating = blackPlayer.ratings
                ? blackPlayer.ratings[gameData.timeMode.name].rating
                : undefined

            setPGNHeader({
                Event: `chessclub.com Speed ${gameData.timeMode.durationMinutes}m+${
                    gameData.timeMode.clockIncrementSeconds
                }spm ${new Date().getFullYear()}`,
                Site: 'Internet Chess Club http://chessclub.com/',
                Date: format(new Date(), 'yyyy.MM.dd'),
                Round: '-',
                White: whitePlayer.userName || 'Stockfish',
                Black: blackPlayer.userName || 'Stockfish',
                WhiteTitle: whitePlayer.title,
                BlackTitle: blackPlayer.title,
                Result: gameResult.outcome || '*',
                WhiteElo: `${whitePlayerOldRating}` || '-',
                BlackElo: `${blackPlayerOldRating}` || '-',
                Opening: opening ? opening : '-',
                ECO: '-',
                BlackAvatarUrl: blackPlayer.avatarUrl,
                WhiteAvatarUrl: whitePlayer.avatarUrl,
                BlackCountry: blackPlayer.countryId,
                WhiteCountry: whitePlayer.countryId,
                TimeMode: `${gameData.timeMode.name}`,
                WhiteClock: convertMinutesToTime(gameData.timeMode.durationMinutes),
                BlackClock: convertMinutesToTime(gameData.timeMode.durationMinutes),
                Time: format(new Date(), 'HH:mm:ss'),
                TimeControl: `${gameData.timeMode.durationMinutes}+${gameData.timeMode.clockIncrementSeconds}`,
            })
            setResultType(gameResult.type)
        }

        setAnalysisGameTree(gameTree)
        setActiveAnalysisTab(0)
        setAnalysisCurrentPositionId(getLastPosition(gameTree).id)
        resetGame(GameViewState.PRE_GAME)
        clearGSConnection()
        navigate('/analysis')
    }

    const onRematchClick = () => {
        if (gameData?.matchKind === 'human_vs_ai') {
            setActiveTab(2)
        } else {
            setActiveTab(0)
            setDrawerOpen('open')
        }
        if (gameData?.matchId) {
            // REMATCH UPDATE
            sendRematchChallenge({
                matchId: gameData?.matchId,
            })
            analyticsManager.dispatchEvent('offerRematch', {})
        }

        setGameEndEnabled(false)
    }

    const onNewGameClick = (vsHuman: boolean) => {
        setGameEndEnabled(false)
        if (!vsHuman) {
            setActiveTab(2)
            setDrawerOpen('open')
        } else {
            setActiveTab(0)
            setDrawerOpen('open')
        }
        resetGame(GameViewState.PRE_GAME)
    }

    const onDrawClick = useCallback(() => {
        if (!sentOfferDraw) {
            setDialog(ConfirmationType.OFFER_DRAW)
        }
    }, [sentOfferDraw])

    const onAbortClick = useCallback(() => {
        if (!sentOfferAbort) {
            setDialog(ConfirmationType.ASK_FOR_ABORT)
        }
    }, [sentOfferAbort])

    const onFlippedClick = useCallback(() => setFlipped(!flipped), [flipped])

    const onResignClick = useCallback(() => setDialog(ConfirmationType.RESIGN), [])

    // const onDialogPlayClick = () => {
    //     if (userData.state !== 'logged_in') {
    //         setAuthOverlayTrigger('signin')
    //     } else {
    //         setDrawerOpen('open')
    //     }
    // }

    useEffect(() => {
        if (gameState === GameViewState.GAME_RUNNING || gameState === GameViewState.PRE_GAME) {
            setTabBar(0)
            // DEV-2536
            // setGameEndEnabled(true)
        } else {
            sentOfferAbort && setSentOfferAbort(false)
            sentOfferDraw && setSentOfferDraw(false)
            setDialog(undefined)
        }
    }, [gameState, sentOfferAbort, sentOfferDraw, setGameEndEnabled])

    const playerTopBar = (
        <PlayerInfoBar
            showRating={gameData?.matchKind === 'human_vs_human'}
            player={playerTop}
            clock={flipped ? whiteClock : blackClock}
            color={flipped ? Color.White : Color.Black}
            gameState={gameState}
            gtpos={gtpos}
            top={true}
            view={PageView.GAME}
            overallRecord={userData.userData?.id === playerTop.playerId ? undefined : overallRecord}
        />
    )

    const playerBottomBar = (
        <PlayerInfoBar
            showRating={gameData?.matchKind === 'human_vs_human'}
            player={playerBottom}
            clock={flipped ? blackClock : whiteClock}
            color={flipped ? Color.Black : Color.White}
            gameState={gameState}
            gtpos={gtpos}
            top={false}
            view={PageView.GAME}
            overallRecord={userData.userData?.id === playerBottom.playerId ? undefined : overallRecord}
        />
    )

    const innerInfo = (
        <>
            {tabBar === 0 && (
                <>
                    <Notation
                        vertical={layout === Layout.MOBILE}
                        gameTree={gameTree}
                        currentPositionId={currentPositionId}
                        gameResult={gameResult}
                        matchTitle={matchTitle}
                        editableAnnotations={false}
                        rated={gameState === GameViewState.PRE_GAME ? undefined : isRated}
                        vsComputer={gameData?.matchKind === 'human_vs_ai'}
                        showBotLevel={showBotLevel}
                        opening={opening}
                        sx={{ order: { xs: 3, md: 'unset' } }}
                    />
                    <NotationControls
                        sx={{ order: { xs: 2, md: 'unset' } }}
                        vertical={layout === Layout.MOBILE}
                        hasNextMoves={hasNextMoves}
                        hasPrevMoves={hasPrevMoves}
                        onFirstMove={setToFirstPosition}
                        onPrevMove={setToPreviousPosition}
                        onNextMove={setToNextPosition}
                        onLastMove={setToLastPosition}
                    />
                </>
            )}
            {gameState === GameViewState.GAME_OVER && (
                <Stack direction="row" justifyContent={'center'} spacing={1} order={{ xs: 1, md: 'unset' }}>
                    <Button
                        variant={'contained'}
                        onClick={() => {
                            setDrawerOpen('open')
                            resetGame(GameViewState.PRE_GAME)
                        }}
                    >
                        Play
                    </Button>
                    <Button
                        variant={'contained'}
                        onClick={() => {
                            if (!gameTree || !currentPositionId) {
                                navigate('/analysis')
                            } else {
                                onAnalysisClick()
                            }
                        }}
                    >
                        Analysis
                    </Button>
                    <Button
                        variant={'contained'}
                        onClick={() => {
                            navigate('/watch')
                            resetGame(GameViewState.PRE_GAME)
                        }}
                    >
                        Watch
                    </Button>
                </Stack>
            )}
        </>
    )

    return (
        <AppLayout
            aboveBoard={layout === Layout.MOBILE ? playerTopBar : undefined}
            board={
                <>
                    <ChessBoard
                        mobile={layout === Layout.MOBILE}
                        gameTree={gameTree}
                        currentPositionId={currentPositionId}
                        position={gtpos.position}
                        flipped={flipped}
                        onMove={onMove}
                        gameRunning={gameState === GameViewState.GAME_RUNNING}
                        myColor={myColor}
                    />
                    {gameState === GameViewState.GAME_PRELOADING && <BoardOverlay />}
                </>
            }
            dataProvider={<GameServerConnector />}
            belowBoard={layout === Layout.MOBILE ? playerBottomBar : undefined}
            layout={layout}
        >
            <>
                {/* TODO: MOVE TO LAYOUT */}

                {(gameState !== GameViewState.PRE_GAME && drawerOpen === 'closed') ||
                gameState === GameViewState.GAME_PRELOADING ? (
                    <>
                        {layout !== Layout.MOBILE && playerTopBar}
                        {layout !== Layout.MOBILE && innerInfo}
                        {gameState === GameViewState.GAME_RUNNING || gameState === GameViewState.GAME_PRELOADING ? (
                            <>
                                {layout !== Layout.MOBILE && !!(playerTop.human && playerBottom.human) && (
                                    <Scoreboard
                                        pairId={gameData?.matchId || ''}
                                        useBorder
                                        topUserId={playerTop.playerId || ''}
                                        bottomUserId={playerBottom.playerId || ''}
                                    />
                                )}
                                <GameEndControls
                                    disabled={gameState !== GameViewState.GAME_RUNNING}
                                    onAbortClick={onAbortClick}
                                    onDrawClick={onDrawClick}
                                    onResignClick={onResignClick}
                                    onFlipClick={onFlippedClick}
                                    drawEnabled={true}
                                    sentOfferAbort={sentOfferAbort}
                                    setSentOfferAbort={setSentOfferAbort}
                                    sentOfferDraw={sentOfferDraw}
                                    setSentOfferDraw={setSentOfferDraw}
                                    vsHuman={gameData?.matchKind === 'human_vs_human'}
                                />
                            </>
                        ) : null}
                        {layout !== Layout.MOBILE && playerBottomBar}
                    </>
                ) : null}
            </>

            {layout === Layout.MOBILE && gameState !== GameViewState.PRE_GAME && innerInfo}
            {(gameState === GameViewState.GAME_RUNNING || gameState === GameViewState.GAME_PRELOADING) &&
                layout === Layout.MOBILE &&
                !!(playerTop.human && playerBottom.human) && (
                    <Box sx={{ order: 5, m: 'auto', width: '100%' }}>
                        <Scoreboard
                            pairId={gameData?.matchId || ''}
                            useBorder
                            topUserId={playerTop.playerId || ''}
                            bottomUserId={playerBottom.playerId || ''}
                        />
                    </Box>
                )}

            {dialog && (
                <ConfirmationDialogs
                    type={dialog}
                    onClose={handleCancel}
                    onConfirm={handleConfirm}
                    rematchUser={rematchMatchData?.userName ? rematchMatchData.userName : 'Opponent'}
                />
            )}
            {gameState === GameViewState.GAME_OVER && gameResult && gameData ? (
                <GameOverDialog
                    open={gameEndOpen}
                    onClose={() => setGameEndEnabled(false)}
                    gameData={gameData}
                    result={gameResult}
                    onAnalysis={onAnalysisClick}
                    onNewGame={onNewGameClick}
                    onRematch={onRematchClick}
                    myColor={myColor}
                    layout={layout}
                    sessionResultHistory={sessionResultHistory}
                />
            ) : null}
            <ConnectionFailedOverlay connectionStatus={connectionStatus} />
            <StyledSnackbar isOpen={snackBarOpen} message={snackbarMessage} onClose={handleSnackBarOpenClose} />
            <OpponentDisconnectedSnackbar />
            <ConfirmationVictoryDialog />
        </AppLayout>
    )
}
