//import { Radio } from '@mui/material'
import { Box, Button, Typography } from '@mui/material'
import { Color } from '../../chess/types'
import { ColorPiece } from './RadioButtons/ColorPiece'
import { Random } from './RadioButtons/Random'

export enum ColorSelection {
    WHITE = 'white',
    BLACK = 'black',
    RANDOM = 'no_color',
}

export type ColorSelectorProps = {
    value: ColorSelection
    onChange: (color: ColorSelection) => void
}

export function ColorSelector(props: ColorSelectorProps) {
    const { value, onChange } = props

    return (
        <Box>
            <Typography variant="h6">Color</Typography>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '8px',
                }}
            >
                <Button
                    className="colorfulSvg"
                    variant={value === ColorSelection.WHITE ? 'contained' : 'outlined'}
                    onClick={() => onChange(ColorSelection.WHITE)}
                >
                    <ColorPiece color={Color.White} />
                </Button>
                <Button
                    className="colorfulSvg"
                    variant={value === ColorSelection.RANDOM ? 'contained' : 'outlined'}
                    onClick={() => onChange(ColorSelection.RANDOM)}
                >
                    <Random />
                </Button>
                <Button
                    className="colorfulSvg"
                    variant={value === ColorSelection.BLACK ? 'contained' : 'outlined'}
                    onClick={() => onChange(ColorSelection.BLACK)}
                >
                    <ColorPiece color={Color.Black} />
                </Button>
            </Box>
        </Box>
    )
}
