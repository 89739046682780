import { Box } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { ColorSelection } from '../../../components/colorSelector/ColorSelector'
import { LoginHint } from '../../../components/logginhint/LoginHint'
import { NewChallengeSelector } from '../../../components/newChallengeSelector/NewChallengeSelector'
// import { shuffleArray } from '../../../helpers'
import { ChallengeVariant, Challenges } from '../../../components/matchmaking/Challenges'
import { LoggedInUserData } from '../../../sharedComponents/src/globalHeader/GlobalHeader'
import { analyticsManager } from '../../../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { ChallengeEntry, MMChallengeType, TimeMode } from '../../../store/types'

export type playAnyonePanelProps = {
    onCustomChallengeClick: () => void
    loggedIn: boolean
    userData: LoggedInUserData
}

const timeModeToString = (timeMode: TimeMode) => {
    return `${timeMode.durationMinutes},${timeMode.clockIncrementSeconds},${timeMode.name}`
}

function PlayAnyonePanel(props: playAnyonePanelProps) {
    const [isPlayAny, setPlayAny] = useState<boolean>(false)
    const { onCustomChallengeClick, loggedIn, userData } = props

    const sendPlayerChallenge = useStoreActions((state) => state.matchMaker.sendPlayerChallenge)
    const acceptChallenge = useStoreActions((state) => state.matchMaker.acceptChallenge)
    const sendDeleteChallenge = useStoreActions((state) => state.matchMaker.sendDeleteChallenge)
    const sendRejectChallenge = useStoreActions((state) => state.matchMaker.sendRejectChallenge)

    // --------------------- Launched Challenges ---------------------

    const autoChallenges = useStoreState((state) => state.matchMaker.autoChallengesByMe)
    const customChallengesByMe = useStoreState((state) => state.matchMaker.customChallengesByMe)
    const directChallengesByMe = useStoreState((state) => state.matchMaker.directChallengesByMe)
    const challengesToMe = useStoreState((state) => state.matchMaker.challengesToMe)
    const openChallenges = useStoreState((state) => state.matchMaker.openChallenges)

    let autoChallengesStrings = useMemo(
        () =>
            autoChallenges.map((challenge) => {
                return timeModeToString(challenge.timeMode)
            }),
        [autoChallenges.length],
    )

    const challengeButtons = [
        '1,0,bullet',
        '3,0,blitz',
        '3,2,blitz',
        '5,0,blitz',
        '5,3,blitz',
        '10,0,rapid',
        '15,10,rapid',
        '30,0,rapid',
    ]

    useEffect(() => {
        if (challengeButtons.every((challenge) => autoChallengesStrings.includes(challenge))) {
            setPlayAny(true)
        } else {
            setPlayAny(false)
        }
    }, [autoChallengesStrings])

    const onSelectedTimeHandler = (newSelectedAutoChallenges: string[]) => {
        if (!loggedIn) return

        const challengeMap: Record<string, ChallengeEntry> = {}
        autoChallenges.map((c) => (challengeMap[timeModeToString(c.timeMode)] = c))

        challengeButtons.forEach((cstr) => {
            if (autoChallengesStrings.includes(cstr) && !newSelectedAutoChallenges.includes(cstr)) {
                sendDeleteChallenge(challengeMap[cstr].challengeId)
                autoChallengesStrings = autoChallengesStrings.filter((c) => c !== cstr)
                let challengeString = timeModeToString(challengeMap[cstr].timeMode)
                challengeString = challengeString.replace(/,/g, '-')
                analyticsManager.dispatchEvent('exitAutoPool', { challengeString })
            }
            if (!autoChallengesStrings.includes(cstr) && newSelectedAutoChallenges.includes(cstr)) {
                const challenge = {
                    color: ColorSelection.RANDOM,
                    rated: true,
                    time: Number(newSelectedAutoChallenges[newSelectedAutoChallenges.length - 1].split(',')[0]),
                    increment: Number(newSelectedAutoChallenges[newSelectedAutoChallenges.length - 1].split(',')[1]),
                    challengeType: MMChallengeType.AUTO,
                }
                sendPlayerChallenge(challenge)
                let challengeString = newSelectedAutoChallenges[newSelectedAutoChallenges.length - 1].replace(/,/g, '-')

                analyticsManager.dispatchEvent('enterAutoPool', { challengeString })
            }
        })
    }

    // const handlePlayAny = () => {
    //     if (isPlayAny) {
    //         onSelectedTimeHandler([])
    //     } else {
    //         onSelectedTimeHandler([])
    //         shuffleArray(challengeButtons).forEach((challenge) => onSelectedTimeHandler([challenge]))
    //     }
    // }

    const onDeleteChallengeHandler = (challenge: ChallengeEntry) => {
        if (challenge.challengeType === MMChallengeType.DIRECT && challenge.pairId) {
            sendRejectChallenge({ pairId: challenge.pairId, isRematch: false })
        } else {
            sendDeleteChallenge(challenge.challengeId)
        }
    }

    const onAcceptChallengeHandler = (challenge: ChallengeEntry) => {
        if (!!challenge.challengeId) {
            acceptChallenge({
                challengeType: challenge.challengeType,
                id: challenge.challengeType === MMChallengeType.CUSTOM ? challenge.challengeId : challenge.pairId!,
            })
        }
    }

    function calculateEtime(timeMode: TimeMode) {
        return timeMode.durationMinutes + (timeMode.clockIncrementSeconds * 2) / 3
    }

    function sortChallenges(array: ChallengeEntry[]) {
        if (array && array.length > 0) {
            array.sort((a, b) => {
                const etimeA = calculateEtime(a.timeMode)
                const etimeB = calculateEtime(b.timeMode)
                return etimeA - etimeB
            })
        }
    }

    sortChallenges(openChallenges)
    sortChallenges(challengesToMe)
    sortChallenges(directChallengesByMe)
    sortChallenges(customChallengesByMe)

    return (
        <Box p={0} pb="16px" gap="16px" display="flex" flexDirection="column">
            <NewChallengeSelector
                headerTitle="Click to be auto paired"
                launchedChallenges={autoChallengesStrings}
                onSelected={onSelectedTimeHandler}
                vsHuman={true}
                buttons={challengeButtons}
                isPlayAny={isPlayAny}
                handleCustomButton={onCustomChallengeClick}
                // handlePlayAny={handlePlayAny}
            />

            {loggedIn && (
                <>
                    <Challenges
                        variant={ChallengeVariant.DIRECT_CHALLENGES}
                        challenges={challengesToMe}
                        handleAction={onAcceptChallengeHandler}
                    />
                    <Challenges
                        variant={ChallengeVariant.MY_CHALLENGES}
                        challenges={customChallengesByMe.concat(directChallengesByMe)}
                        handleAction={onDeleteChallengeHandler}
                    />
                    <Challenges
                        variant={ChallengeVariant.OPEN_CHALLENGES}
                        challenges={openChallenges}
                        handleAction={onAcceptChallengeHandler}
                    />
                </>
            )}
            {!loggedIn && <LoginHint />}
        </Box>
    )
}

export default PlayAnyonePanel
