import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useEffect } from 'react'
import { useStoreActions, useStoreState } from '../../../../store/hooks'
import { Bot } from '../../../../store/types'
import updateSelectedBotData from '../hooks/updateSelectedBotData'
import BotAvatar from './BotAvatar'

const BotsList: React.FC = () => {
    const settings = useStoreState((state) => state.gameView.settings)
    const bots = useStoreState((state) => state.matchMaker.bots)
    const selectedBot = useStoreState((state) => state.matchMaker.searchOptions.computerChallenge.selectedBot)
    const fullSearchOptions = useStoreState((state) => state.matchMaker.searchOptions)

    const setSearchOptions = useStoreActions((state) => state.matchMaker.setSearchOptions)
    const setPlayComputerFen = useStoreActions((state) => state.analysisMode.setPlayComputerFen)

    const onBotSelect = (bot: Bot) => {
        const updatedSearchOptions = updateSelectedBotData(fullSearchOptions, bot)
        setSearchOptions(updatedSearchOptions)
    }

    useEffect(() => {
        if (selectedBot) {
            setPlayComputerFen(selectedBot.startingFEN || '')
        }
    }, [selectedBot])

    return (
        <Grid container>
            {bots.map((bot) => (
                <Grid
                    key={bot.id}
                    item
                    xs={4}
                    sx={{
                        mt: 1,
                        cursor: 'pointer',
                        px: 0.5,
                        pb: 2,
                        borderBottom: '6px solid',
                        borderBottomColor: bot.id === selectedBot?.id ? 'text.primary' : 'text.disabled',
                        opacity: bot.id === selectedBot?.id ? 1 : 0.5,
                    }}
                    onClick={() => onBotSelect(bot)}
                >
                    <Stack
                        sx={{
                            border: '2px solid',
                            borderColor: bot.id === selectedBot?.id ? 'primary.main' : 'transparent',
                        }}
                    >
                        <BotAvatar bot={bot} selectedBot={selectedBot} />
                        <Stack
                            alignItems="center"
                            sx={{
                                pb: 0.5,
                            }}
                        >
                            <Typography variant="h6">{bot.name}</Typography>
                            <Typography variant="body2" fontSize={'14px'}>
                                {`${bot.strengthLevel ? bot.strengthLevel : !settings.friendlyMode ? bot.strengthRating : 'custom'}`}
                            </Typography>
                        </Stack>
                    </Stack>
                </Grid>
            ))}
        </Grid>
    )
}

BotsList.displayName = 'BotsList'

export default BotsList
