import { useEffect } from 'react'
import { UserData } from '../../GlobalHeader'
import {
    AcceptRematchEvent,
    AnalyticsEventMap,
    ClickVideoEvent,
    CloseGuestWelcomeEvent,
    CompleteLoginEvent,
    CompleteSignupEvent,
    ComputerChallengeEvent,
    ConfirmEmailEvent,
    CreateGuestAccountEvent,
    CustomChallengeEvent,
    EnterAutoPoolEvent,
    ExitAutoPoolEvent,
    GameFinishedEvent,
    GameStartedEvent,
    LogoutEvent,
    NextPuzzleEvent,
    OfferAbortEvent,
    OfferDrawEvent,
    OfferRematchEvent,
    OpenLoginEvent,
    OpenSignupEvent,
    RequestResendEvent,
    ResignEvent,
    SignupReachSecondPageEvent,
    TimeInQueueEvent,
    analyticsManager,
} from './AnalyticsManager'
import { getHighestUserGroupName } from './utils'

type Props = {
    env: string
    userData: UserData | undefined
}

export const AnalyticsLoggingServiceComponent = ({ env, userData }: Props) => {
    useEffect(() => {
        if (env !== 'dev' && env !== 'local') return

        const userId = userData?.id
        const userName = userData?.user_name
        const userType = userId ? getHighestUserGroupName(userData.groups) : 'visitor'

        const eventHandlers: { [key in keyof AnalyticsEventMap]?: Function } = {
            openSignup: (e: OpenSignupEvent) => {
                console.log('[AnalyticsLoggingService] open signup event', e, { env, userId, userType, userName })
            },
            openLogin: (e: OpenLoginEvent) => {
                console.log('[AnalyticsLoggingService] open login event', e, { env, userId, userType, userName })
            },
            createGuestAccount: (e: CreateGuestAccountEvent) => {
                console.log('[AnalyticsLoggingService] create guest account event', e, {
                    env,
                    userId,
                    userType,
                    userName,
                })
            },
            closeGuestWelcome: (e: CloseGuestWelcomeEvent) => {
                console.log('[AnalyticsLoggingService] close guest welcome event', e, {
                    env,
                    userId,
                    userType,
                    userName,
                })
            },
            signupReachSecondPage: (e: SignupReachSecondPageEvent) => {
                console.log('[AnalyticsLoggingService] signup reach second page event', e, {
                    env,
                    userId,
                    userType,
                    userName,
                })
            },
            completeSignup: (e: CompleteSignupEvent) => {
                console.log('[AnalyticsLoggingService] complete signup event', e, { env, userId, userType, userName })
            },
            requestResend: (e: RequestResendEvent) => {
                console.log('[AnalyticsLoggingService] request resend event', e, { env, userId, userType, userName })
            },
            confirmEmail: (e: ConfirmEmailEvent) => {
                console.log('[AnalyticsLoggingService] confirm email event', e, { env, userId, userType, userName })
            },
            completeLogin: (e: CompleteLoginEvent) => {
                console.log('[AnalyticsLoggingService] complete login event', e, { env, userId, userType, userName })
            },
            logout: (e: LogoutEvent) => {
                console.log('[AnalyticsLoggingService] logout event', e, { env, userId, userType, userName })
            },
            customChallenge: (e: CustomChallengeEvent) => {
                console.log('[AnalyticsLoggingService] custom challenge event', e, { env, userId, userType, userName })
            },
            computerChallenge: (e: ComputerChallengeEvent) => {
                console.log('[AnalyticsLoggingService] computer challenge event', e, {
                    env,
                    userId,
                    userType,
                    userName,
                })
            },
            enterAutoPool: (e: EnterAutoPoolEvent) => {
                console.log('[AnalyticsLoggingService] enter auto pool event', e, { env, userId, userType, userName })
            },
            exitAutoPool: (e: ExitAutoPoolEvent) => {
                console.log('[AnalyticsLoggingService] exit auto pool event', e, { env, userId, userType, userName })
            },
            timeInQueue: (e: TimeInQueueEvent) => {
                console.log('[AnalyticsLoggingService] time in queue event', e, { env, userId, userType, userName })
            },
            gameStarted: (e: GameStartedEvent) => {
                console.log('[AnalyticsLoggingService] game started event', e, { env, userId, userType, userName })
            },
            resign: (e: ResignEvent) => {
                console.log('[AnalyticsLoggingService] resign event', e, { env, userId, userType, userName })
            },
            offerDraw: (e: OfferDrawEvent) => {
                console.log('[AnalyticsLoggingService] offer draw event', e, { env, userId, userType, userName })
            },
            offerAbort: (e: OfferAbortEvent) => {
                console.log('[AnalyticsLoggingService] offer abort event', e, { env, userId, userType, userName })
            },
            offerRematch: (e: OfferRematchEvent) => {
                console.log('[AnalyticsLoggingService] offer rematch event', e, { env, userId, userType, userName })
            },
            acceptRematch: (e: AcceptRematchEvent) => {
                console.log('[AnalyticsLoggingService] accept rematch event', e, { env, userId, userType, userName })
            },
            gameFinished: (e: GameFinishedEvent) => {
                console.log('[AnalyticsLoggingService] game finished event', e, { env, userId, userType, userName })
            },
            clickVideo: (e: ClickVideoEvent) => {
                console.log('[AnalyticsLoggingService] click video event', e, { env, userId, userType, userName })
            },
            nextPuzzle: (e: NextPuzzleEvent) => {
                console.log('[AnalyticsLoggingService] next puzzle event', e, { env, userId, userType, userName })
            },
        }

        console.log('[AnalyticsLoggingService] initialize analytics logging service')

        // Add listeners using the stored references
        Object.entries(eventHandlers).forEach(([event, handler]) => {
            analyticsManager.addEventListener(event as any, handler as any)
        })

        return () => {
            // Remove listeners using the stored references
            Object.entries(eventHandlers).forEach(([event, handler]) => {
                analyticsManager.removeEventListener(event as any, handler as any)
            })
        }
    }, [env, userData?.id, userData?.groups])

    return null
}
