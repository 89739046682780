import { Action, Thunk, action, createStore, thunk } from 'easy-peasy'
import gameViewModel, { GameViewModel, GameViewState } from '../features/gameView/GameViewModel'

import { RatingType } from '../components/matchmaking/ChallengeCard'
import ObserveViewModel, { ObserveViewConnectionModel } from '../features/ObserveView/ObserveViewModel'
import PuzzlesViewModel, { PuzzlesModel } from '../features/PuzzlesView/PuzzlesViewModel'
import AnalysisViewModel, { GameAnalysisModel } from '../features/analysisView/AnalysisViewModel'
import { BasePGNHeaders } from '../features/analysisView/utils'
import matchMakingConnectionModel, {
    MatchMakingConnectionModel,
} from '../features/matchMakingDrawer/matchMakingConnector/MatchMakingConnectorModel'
import { GroupNames, LoggedInUserData, LoginState, Rules } from '../sharedComponents/src/globalHeader/GlobalHeader'
import GetCookie from '../sharedComponents/src/globalHeader/common/getCookie'
import { ensureLogin, tokenCookieKey } from '../sharedComponents/src/globalHeader/functions/ams-functions'
import { analyticsManager } from '../sharedComponents/src/globalHeader/services/analytics/AnalyticsManager'
import { boardColors } from '../sharedComponents/src/globalHeader/theme/colors'
import { MMChallengeType, PGNHeader } from './types'

export type QueueTimeStart = {
    challengeId: string
    challengeType: MMChallengeType
    timeMode: string
    rated: boolean
    timestamp: number
}

export interface StoreModel {
    // TODO: change this name since it is no longer a drawer
    drawerOpen: 'open' | 'closed' | 'loading'
    isDrawerMenuOpen: boolean
    serverConnectionData: boolean
    darkMode: boolean
    activeTab: number
    activeAnalysisTab: number
    userData: LoggedInUserData
    rules: Rules[]
    userGroups: GroupNames[]
    matchMaker: MatchMakingConnectionModel
    PGNHeader: PGNHeader
    unreadMessages: number
    queueTimeStarts: QueueTimeStart[]
    token: string | undefined
    snackbarMessage: string | undefined
    analysisMode: GameAnalysisModel
    gameView: GameViewModel
    observeView: ObserveViewConnectionModel
    puzzlesView: PuzzlesModel
    authOverlayTrigger: string | { name: string; data: any } | undefined
    setServerConnectionData: Action<StoreModel, boolean>
    setToken: Action<StoreModel, string | undefined>
    setDrawerOpen: Action<StoreModel, 'open' | 'closed' | 'loading'>
    setIsDrawerMenuOpen: Action<StoreModel, boolean>
    setDarkMode: Action<StoreModel, boolean>
    setActiveTab: Action<StoreModel, number>
    setUserData: Action<StoreModel, LoggedInUserData>
    setRules: Action<StoreModel, Rules[]>
    setUserGroups: Action<StoreModel, GroupNames[]>
    setActiveAnalysisTab: Action<StoreModel, number>
    setPGNHeader: Action<StoreModel, PGNHeader>
    setUnreadMessages: Action<StoreModel, number>
    setAuthOverlayTrigger: Action<StoreModel, string | { name: string; data: any } | undefined>
    setUpdatedRating: Action<StoreModel, { type: RatingType; newRating: number | undefined }>
    setSnackbarMessage: Action<StoreModel, string | undefined>
    addQueueTimeStart: Action<StoreModel, QueueTimeStart>
    removeQueueTimeStart: Action<StoreModel, string>
    clearQueueTimeStarts: Action<StoreModel>
    initUser: Thunk<StoreModel>
}

export const store = createStore<StoreModel>({
    drawerOpen: 'closed',
    isDrawerMenuOpen: false,
    serverConnectionData: false,
    darkMode: true,
    activeTab: 0,
    activeAnalysisTab: 0,
    queueTimeStarts: [],
    token: undefined,
    unreadMessages: 0,
    matchMaker: matchMakingConnectionModel,
    rules: [],
    userGroups: [],
    PGNHeader: BasePGNHeaders,

    analysisMode: AnalysisViewModel,
    gameView: gameViewModel,
    observeView: ObserveViewModel,
    puzzlesView: PuzzlesViewModel,
    userData: { state: LoginState.INITIALIZING, userData: null, token: '' },
    authOverlayTrigger: undefined,
    snackbarMessage: undefined,

    setDarkMode: action((state, on) => {
        state.darkMode = on
    }),
    setDrawerOpen: action((state, open) => {
        state.drawerOpen = open
    }),
    setIsDrawerMenuOpen: action((state, open) => {
        state.isDrawerMenuOpen = open
    }),
    setActiveTab: action((state, tab) => {
        state.activeTab = tab
    }),
    setActiveAnalysisTab: action((state, tab) => {
        state.activeAnalysisTab = tab
    }),
    setUserData: action((state, ud) => {
        state.userData = ud
    }),
    setRules: action((state, groups) => {
        state.rules = groups
    }),
    setUserGroups: action((state, groups) => {
        state.userGroups = groups
    }),
    setPGNHeader: action((state, header) => {
        state.PGNHeader = header
    }),
    setUnreadMessages: action((state, num) => {
        state.unreadMessages = num
    }),
    setAuthOverlayTrigger: action((state, str) => {
        state.authOverlayTrigger = str
    }),
    setUpdatedRating: action((state, payload) => {
        if (state.userData.userData) {
            state.userData.userData.ratings = {
                ...state.userData.userData.ratings,
                [payload.type]: {
                    ...state.userData.userData.ratings[payload.type],
                    rating: payload.newRating,
                },
            }
        }
    }),
    setToken: action((state, token) => {
        state.token = token
    }),
    setServerConnectionData: action((state, data) => {
        state.serverConnectionData = data
    }),
    setSnackbarMessage: action((state, message) => {
        state.snackbarMessage = message
    }),
    addQueueTimeStart: action((state, time) => {
        state.queueTimeStarts.push(time)
    }),
    removeQueueTimeStart: action((state, challengeId) => {
        const foundQueueTimeStart = state.queueTimeStarts.find((t) => t.challengeId === challengeId)
        if (!foundQueueTimeStart) return

        analyticsManager.dispatchEvent('timeInQueue', {
            challengeType: foundQueueTimeStart.challengeType,
            challengeString: foundQueueTimeStart.timeMode,
            rated: foundQueueTimeStart.rated,
            timeInQueue: Date.now() - foundQueueTimeStart.timestamp,
        })

        state.queueTimeStarts = state.queueTimeStarts.filter((t) => t.challengeId !== challengeId)
    }),
    clearQueueTimeStarts: action((state) => {
        state.queueTimeStarts.forEach((t) => {
            analyticsManager.dispatchEvent('timeInQueue', {
                challengeType: t.challengeType,
                challengeString: t.timeMode,
                rated: t.rated,
                timeInQueue: Date.now() - t.timestamp,
            })
        })

        state.queueTimeStarts = []
    }),
    initUser: thunk(async (actions) => {
        const data = await ensureLogin(import.meta.env.VITE_REACT_APP_AMS_URL)
        if (!!data && !data.error) {
            const token = GetCookie(tokenCookieKey)
            actions.setUserData({
                state: LoginState.LOGGED_IN,
                userData: data,
                token: token,
            })
            if (!!token) {
                actions.setToken(token)
            }
            if (data.groups) {
                const ruleNames = data.groups.flatMap((group) => group.rules.map((rule) => rule.name))
                actions.setRules(ruleNames)
                const userGroups = data.groups.map((group) => group.name)
                actions.setUserGroups(userGroups)
            }
            if (data.game_settings)
                actions.gameView.setSettings({
                    ...data.game_settings,
                    boardStyle: boardColors[data.game_settings.boardStyle] ? data.game_settings.boardStyle : 'default',
                })
        } else {
            actions.gameView.shutDownGSConnection()
            actions.gameView.resetGame(GameViewState.PRE_GAME)
            actions.matchMaker.reset()
            actions.setUserData({ state: LoginState.NOT_LOGGED_IN, userData: null, token: '' })
            actions.setToken('')
        }
    }),
})

export default store
