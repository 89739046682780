import { Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import PracticeIcon from '../../../../assets/icons/practice.svg?react'
import { WEBSITE_URL } from '../../../../env'
import { DialogTemplate } from '../../../../sharedComponents/src/DialogTemplate/DialogTemplate'
import { Layout, useResponsiveSizings } from '../../../../sharedComponents/src/hooks/useResponsiveSizings'
import { useStoreActions } from '../../../../store/hooks'

type Variant = {
    header: { top: string; middle?: string; bottom?: string }
    content: { image: string; header: string; description: string }
    controls: { type: 'link' | 'action'; text: string; to: string; action?: any }[]
    loginHint: boolean
}

type VariantKeys = 'puzzleQuestMember' | 'puzzleQuestPremium' | 'puzzlePracticeMember' | 'puzzlePracticePremium'

const variants: Record<VariantKeys, Variant> = {
    puzzleQuestPremium: {
        header: {
            top: 'go premium',
            middle: 'to',
            bottom: 'unlock',
        },
        content: {
            image: 'svg',
            header: "don't miss out!",
            description: 'to play puzzle quest you must become a premium member.',
        },
        controls: [
            { text: 'become a member', to: `${WEBSITE_URL}/memberships`, type: 'link' },
            { text: 'main menu', to: '/', type: 'link' },
        ],
        loginHint: false,
    },
    puzzleQuestMember: {
        header: {
            top: 'go premium',
            middle: 'to',
            bottom: 'unlock',
        },
        content: {
            image: 'svg',
            header: 'dont miss out!',
            description: 'to play puzzle quest you must become a premium member.',
        },
        controls: [
            { text: 'become a member', to: `${WEBSITE_URL}/memberships`, type: 'link' },
            { text: 'main menu', to: '/', type: 'link' },
        ],
        loginHint: true,
    },
    puzzlePracticePremium: {
        header: {
            top: 'daily limit',
            bottom: 'reached',
        },
        content: {
            image: 'svg',
            header: 'want unlimited puzzles?',
            description:
                'become a premium member to unlock full access to over 3.5 million puzzles without a daily limit',
        },
        controls: [
            { text: 'become a member', to: `${WEBSITE_URL}/memberships`, type: 'link' },
            { text: 'main menu', to: '/', type: 'link' },
        ],
        loginHint: false,
    },
    puzzlePracticeMember: {
        header: {
            top: 'join us!',
        },
        content: {
            image: 'svg',
            header: 'Become a member to access puzzles and unlock countless hours of entertainment.',
            description: 'It’s FREE and only takes a few seconds to register.',
        },
        controls: [
            { text: 'sign up', to: 'signup', type: 'action' },
            { text: 'main menu', to: '/', type: 'link' },
        ],
        loginHint: true,
    },
}

interface PuzzlesLimitDialogProps {
    open: boolean
    variant: VariantKeys
}

export const PuzzlesLimitDialog: React.FC<PuzzlesLimitDialogProps> = ({ open, variant }) => {
    const { layout } = useResponsiveSizings()
    const setAuthOverlayTrigger = useStoreActions((state) => state.setAuthOverlayTrigger)
    const imgSize = layout === Layout.MOBILE ? '5rem' : '11rem'
    const info = variants[variant]

    return (
        <DialogTemplate dialogWidth="38rem" open={open} header={{ ...info.header }}>
            <Box width="100%" display="flex" justifyContent="center">
                <Box
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    alignItems="center"
                    justifyContent="space-between"
                    paddingX={2}
                    paddingY={{ xs: 1, md: 3 }}
                    gap={{ xs: 1, md: 3 }}
                    width="100%"
                >
                    {/* SVG */}
                    <Box>
                        <PracticeIcon style={{ height: imgSize, width: imgSize }} />
                    </Box>
                    <Box>
                        <Typography paragraph fontFamily="Fira Sans" fontSize="1.5rem" align="center" variant="h5">
                            {info.content.header}
                        </Typography>
                        <Typography fontSize="1rem" align="center" variant="body1" textTransform="uppercase">
                            {info.content.description}
                        </Typography>
                    </Box>
                </Box>
            </Box>

            <Box width="100%" display="flex" flexDirection="column" gap={{ xs: 1, md: 1.5 }}>
                {info.controls.map((control, index) =>
                    control.type === 'action' ? (
                        <Button
                            key={control.text + control.to}
                            onClick={() => setAuthOverlayTrigger(control.to)}
                            fullWidth
                            variant={index ? 'outlined' : 'contained'}
                        >
                            {control.text}
                        </Button>
                    ) : (
                        <Link
                            style={{ display: 'block', width: '100%' }}
                            key={control.text + control.to}
                            to={control.to}
                        >
                            <Button fullWidth variant={index ? 'outlined' : 'contained'}>
                                {control.text}
                            </Button>
                        </Link>
                    ),
                )}
            </Box>

            {info.loginHint && (
                <Typography align="center" variant="body2">
                    Already have an account?
                    <br />
                    <a
                        onClick={() => {
                            setAuthOverlayTrigger('signin')

                            //TODO
                            // analyticsManager.dispatchEvent('openLogin', {
                            //     origin: 'puzzleTraining',
                            // })
                        }}
                    >
                        Log in
                    </a>
                </Typography>
            )}
        </DialogTemplate>
    )
}

export default PuzzlesLimitDialog
