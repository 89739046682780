import { Color, Piece, Position, Square } from './types'

// generate a Position object from a FEN string
export function positionFromFEN(fen: string): Position {
    const pos: Position = {
        board: new Array(64),
        turn: Color.White,
        castling: {
            whiteShort: false,
            whiteLong: false,
            blackShort: false,
            blackLong: false,
        },
        enPassant: null,
        moveNum: 1,
        halfmoveClock: 0,
    }

    const parts = fen.split(/\s+/g)
    const posParts = parts[0].split('/')
    if (posParts.length !== 8) {
        throw new Error('invalid FEN string, expected 8 rows')
    }

    for (let i = 0; i < 64; i++) {
        pos.board[i] = null
    }

    for (let y = 0; y < 8; y++) {
        let x = 0
        for (let i = 0; i < posParts[y].length; i++) {
            const c = posParts[y][i]
            switch (c) {
                case 'p':
                    pos.board[y * 8 + x] = Piece.BlackPawn
                    break
                case 'n':
                    pos.board[y * 8 + x] = Piece.BlackKnight
                    break
                case 'b':
                    pos.board[y * 8 + x] = Piece.BlackBishop
                    break
                case 'r':
                    pos.board[y * 8 + x] = Piece.BlackRook
                    break
                case 'q':
                    pos.board[y * 8 + x] = Piece.BlackQueen
                    break
                case 'k':
                    pos.board[y * 8 + x] = Piece.BlackKing
                    break
                case 'P':
                    pos.board[y * 8 + x] = Piece.WhitePawn
                    break
                case 'N':
                    pos.board[y * 8 + x] = Piece.WhiteKnight
                    break
                case 'B':
                    pos.board[y * 8 + x] = Piece.WhiteBishop
                    break
                case 'R':
                    pos.board[y * 8 + x] = Piece.WhiteRook
                    break
                case 'Q':
                    pos.board[y * 8 + x] = Piece.WhiteQueen
                    break
                case 'K':
                    pos.board[y * 8 + x] = Piece.WhiteKing
                    break
                case '1':
                    break
                case '2':
                    x++
                    break
                case '3':
                    x += 2
                    break
                case '4':
                    x += 3
                    break
                case '5':
                    x += 4
                    break
                case '6':
                    x += 5
                    break
                case '7':
                    x += 6
                    break
                case '8':
                    x += 7
                    break
            }
            x++
        }
    }

    if (parts.length >= 1 && parts[1] === 'b') {
        pos.turn = Color.Black
    }

    if (parts.length >= 2) {
        for (let i = 0; i < parts[2].length; i++) {
            const c = parts[2][i]
            switch (c) {
                case 'k':
                    pos.castling.blackShort = true
                    break
                case 'q':
                    pos.castling.blackLong = true
                    break
                case 'K':
                    pos.castling.whiteShort = true
                    break
                case 'Q':
                    pos.castling.whiteLong = true
                    break
            }
        }
    }

    if (parts.length >= 3 && parts[3] !== '-') {
        const sqr = Square[parts[3].toUpperCase()]
        if (sqr >= 0 && sqr < 64) {
            pos.enPassant = sqr
        }
    }

    if (parts.length >= 4) {
        const halfmoveClock = Number(parts[4])
        if (!isNaN(halfmoveClock)) {
            pos.halfmoveClock = halfmoveClock
        }
    }

    if (parts.length >= 5) {
        const moveNum = Number(parts[5])
        if (!isNaN(moveNum)) {
            pos.moveNum = moveNum
        }
    }

    return pos
}

// Convert a Position object to a FEN string
export function positionToFEN(pos: Position): string {
    const chars = 'kqrbnp_PNBRQK'
    let fen = ''

    for (let y = 0; y < 8; y++) {
        if (y > 0) {
            fen += '/'
        }
        let count = 0
        for (let x = 0; x < 8; x++) {
            const sqr: Square = y * 8 + x
            const piece = pos.board[sqr]
            if (piece === null || piece === 0) {
                count++
            } else {
                if (count > 0) {
                    fen += count
                    count = 0
                }
                fen += chars[piece + 6]
            }
        }
        if (count > 0) {
            fen += count
            count = 0
        }
    }

    fen += pos.turn === Color.White ? ' w ' : ' b '

    let any = false
    if (pos.castling.whiteShort) {
        any = true
        fen += 'K'
    }
    if (pos.castling.whiteLong) {
        any = true
        fen += 'Q'
    }
    if (pos.castling.blackShort) {
        any = true
        fen += 'k'
    }
    if (pos.castling.blackLong) {
        any = true
        fen += 'q'
    }
    if (!any) {
        fen += '-'
    }

    fen += ' '

    if (pos.enPassant !== null) {
        fen += Square[pos.enPassant].toLowerCase() + ' '
    } else {
        fen += '- '
    }

    fen += pos.halfmoveClock + ' ' + pos.moveNum

    return fen
}

const isValidNumber = (num: number) => !isNaN(num) && num >= 0

//check if a FEN string is valid
export const isValidFen = (fen: string) => {
    const fenParts = fen.split(' ')
    if (fenParts.length !== 6) return false
    const [board, turn, castling, enPassant, halfMoveClock, fullMoveNumber] = fenParts
    const boardParts = board.split('/')
    if (boardParts.length !== 8) return false
    const validPieces = ['K', 'Q', 'R', 'B', 'N', 'P', 'k', 'q', 'r', 'b', 'n', 'p']
    for (let i = 0; i < boardParts.length; i++) {
        const part = boardParts[i]
        let count = 0
        for (let j = 0; j < part.length; j++) {
            const char = part[j]
            if ('12345678'.includes(char)) {
                count += Number(char)
            } else if (validPieces.includes(char)) {
                count++
            } else {
                return false
            }
        }
        if (count !== 8) return false
    }
    if (turn !== 'w' && turn !== 'b') return false
    const validCastling = ['-', 'K', 'Q', 'k', 'q', 'KQ', 'Kk', 'Qq', 'KQk', 'KQq', 'Kkq', 'Qkq', 'KQkq']
    if (!validCastling.includes(castling)) return false
    if (enPassant !== '-' && enPassant.length !== 2) return false
    if (!isValidNumber(Number(halfMoveClock))) return false
    if (!isValidNumber(Number(fullMoveNumber))) return false
    if (!board.includes('K') || !board.includes('k')) return false
    // TODO: checked a lot of fens. Still gives some false positives and negatives. Removed king side by side check for now. Worked 90% of the time.
    return true
}
